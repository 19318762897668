/**
 * All of the events that are being tracked. Events are organized hierarchically
 * according to ther context (product area/feature), object, and action. Event
 * names should be lowercased and snake_cased, and the context/object/action
 * sections should be delimited by double underscores.
 *
 * See Notion Document for more information about frontend event tracking:
 * https://www.notion.so/captivateiq/RFC-Frontend-Event-Tracking-ba97572a638c413cb0f070bc27a63d6f
 */
export const EVENT = {
  tq: {
    landing_page: {
      displayed: 'tq__landing_page__displayed',
    },
  },
  list_planning_cycles: {
    search_query: {
      changed: 'list_planning_cycles__search_query__changed',
    },
    planning_cycle: {
      created: 'list_planning_cycles__planning_cycle__created',
      updated: 'list_planning_cycles__planning_cycle__updated',
      deleted: 'list_planning_cycles__planning_cycle__deleted',
    },
  },
  list_tq_models: {
    tq_model: {
      created: 'list_tq_models__tq_model__created',
      updated: 'list_tq_models__tq_model__updated',
      deleted: 'list_tq_models__tq_model__deleted',
    },
  },
  data_grid: {
    territories: {
      imported: 'data_grid__territories__imported',
      exported: 'data_grid__territories__exported',
    },
    accounts: {
      territory_assignment_changed: 'data_grid__accounts__territory_assignment_changed',
    },
    tab_view: {
      accounts_displayed: 'data_grid__tab_view__accounts_displayed',
      territories_displayed: 'data_grid__tab_view__territories_displayed',
    },
  },
  territory_tree: {
    actions: {
      edit_name: 'territory_tree__actions__edit_name',
      move_up: 'territory_tree__actions__move_up',
      move_down: 'territory_tree__actions__move_down',
      move_to: 'territory_tree__actions__move_to',
      add_territory_as_parent: 'territory_tree__actions__add_territory_as_parent',
      add_territory_as_child: 'territory_tree__actions__add_territory_as_child',
    },
  },
  collaboration: {
    collaborators_management: {
      user_invited: 'collaboration__collaborators_management__user_invited',
    },
  },
  account_dealer: {
    rules: {
      displayed: 'account_dealer__rules__displayed',
      hidden: 'account_dealer__rules__hidden',
      executed: 'account_dealer__rules__executed',
    },
  },
} as const;
